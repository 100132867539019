import React from "react";

import "./css/normalize.css";
import "./css/skeleton.css";

const Privacy = () => {
  return (
    <div className="six columns offset-by-three">
      <h2>Simiotics Search: Privacy</h2>
      <p>
        Every query that you send to Simiotics gets stored in our logs.
        We also track which responses you click on.
      </p>
      <p>
        Information is aggregated by session. Each visit to Simiotics represents
        a distinct session.
      </p>
      <p>We use the information we collect to make decisions about:</p>
      <ol>
        <li>How to improve our query normalizers</li>
        <li>How to improve our indices</li>
        <li>How to improve our user interface</li>
        <li>
          Which languages, frameworks, and libraries to integrate into our
          search engine
        </li>
      </ol>
      <p>
        We do not directly collect user information beyond search queries,
        results, and result clicks. We do use third-party tools like Google
        Analytics and Mixpanel which may collect this information.
      </p>
      <p>We do not sell your data to anybody.</p>
    </div>
  );
};

export default Privacy;
