import React from "react";
import Mousetrap from "mousetrap";

import "./css/normalize.css";
import "./css/skeleton.css";

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.queryInput = React.createRef();
    this.searchButton = React.createRef();
    this.searchGoogleButton = React.createRef();
    this.searchStackOverflowButton = React.createRef();
    this.state = {
      query: ""
    };
  }

  componentDidMount() {
    Mousetrap.bind("mod+enter", () => {
      this.props.onSearch(this.state.query);
    });
    Mousetrap.bind("alt+g", this.googleSearch);
    Mousetrap.bind("alt+s", this.stackoverflowSearch);
    this.queryInput.current.focus();
  }

  componentDidUpdate(prevProps) {
    if (this.props.query && this.props.query !== prevProps.query) {
      this.setState({query: this.props.query}, () => {
        this.queryInput.current.value = this.state.query;
        if (this.props.autoSearch) {
          this.props.onSearch(this.state.query).then(() => console.log(`Auto search complete for query: ${this.state.query}`));
        }
      });
    }
  }

  componentWillUnmount() {
    Mousetrap.unbind("mod+enter");
    Mousetrap.unbind("alt+g");
    Mousetrap.unbind("alt+s");
  }

  /**
   * Generates an onChange handler for form input fields. Modifies the appropriate key inside
   * the component state to reflect the current value of each input field.
   *
   * @param {string} stateKey - Key in state object which the input field corresponds to
   */
  generateChangeHandler(stateKey) {
    const changeHandler = event => {
      var stateUpdate = {};
      stateUpdate[stateKey] = event.target.value;
      this.setState(stateUpdate);
    };

    return changeHandler;
  }

  /**
   * Executes a search query against the given Simiotics Search URL (stored in state).
   */
  handleSearch = event => {
    event.preventDefault();
    this.props.onSearch(this.state.query);
  };

  googleSearch = () => {
    window.open(`https://www.google.com/search?q=${this.state.query}`, "_blank")
  }

  stackoverflowSearch = () => {
    window.open(`https://stackoverflow.com/search?q=${this.state.query}`, "_blank")
  }

  render() {
    return (
      <div className="container">
        <center>
          <h1>Bugout</h1>
        </center>
        <form id="portal">
          <div className="row">
            <label htmlFor="ErrorMessage">Search for Python code or error messages</label>
            <textarea
              className="u-full-width mousetrap"
              id="SearchQuery"
              ref={this.queryInput}
              onChange={this.generateChangeHandler("query")}
            />
          </div>
          <div className="row">
            <div className="row">
              <input
                id="Search"
                className="u-full-width u-pull-right button-primary"
                type="button"
                value="Search"
                ref={this.searchButton}
                onClick={this.handleSearch}
              />
            </div>
          </div>
          <div className="row" id="external-search">
            <input
              id="Search-Google"
              className="six columns"
              type="button"
              value="Search on Google"
              onClick={this.googleSearch}
            />
            <input
              id="Search-Stack-Overflow"
              className="six columns"
              type="button"
              value="Search on Stack Overflow"
              onClick={this.stackoverflowSearch}
            />
          </div>
        </form>
      </div>
    );
  }
}

export { SearchForm };
