import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { defaultStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import './css/normalize.css';
import './css/skeleton.css';

class Results extends React.Component {
    state = {
        query: "",
        results: []
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.query !== this.state.query) {
            this.setState({query: nextProps.query, results: nextProps.results});
            return true;
        }
        return false;
    }

    renderResult = (result, idx) => {
        let snippet = (
            <textarea className="u-full-width">
                {result.snippet}
            </textarea>
        );

        if (result.is_code) {
            snippet = (
                <SyntaxHighlighter language="python" style={defaultStyle}>
                    {result.snippet}
                </SyntaxHighlighter>
            );
        }

        return (
            <div key={idx}>
                <hr></hr>
                <div className="row" key="snippet">
                    <div className="twelve columns u-full-width">
                        {snippet}
                    </div>
                </div>
                <div className="row" key="name">
                    <div className="twelve columns u-full-width">
                        <a href={result.url} target="_blank" rel="noopener noreferrer">
                            {result.display || result.name || "View source"}
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let renderedResult = this.state.results.map(this.renderResult);
        const apology = 'Sorry, Bugout could not find any code relevant to your query.';
        if (this.state.results.length === 0 && this.state.query !== "") {
            renderedResult = apology;
        }
        return (
            <div className="container" id="results-list">
                {renderedResult}
            </div>
        )
    }
}

export {Results};
