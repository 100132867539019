import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

import Home from "./Home";
import Privacy from "./Privacy";
import Header from "./Header";

import "./css/normalize.css";
import "./css/skeleton.css";
import "./css/index.css";

const App = () => {
  const history = createBrowserHistory();
  return (
    <div className="main-container">
      <BrowserRouter history={history}>
        <Header />
        <Switch>
          <Route exact path="/" component={withRouter(Home)} />
          <Route exact path="/privacy" component={Privacy} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
