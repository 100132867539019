import React from "react";

import "./header.css";

import { NavLink } from "react-router-dom";

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="header-container" id="simiotics-header">
          <div className="width-third"></div>
          <div className="width-third">
            <div>Bugout: The Search Engine for Programmers</div>
            <div>
              {this.props.message
                ? `Session ${this.props.clientID}: ${this.props.message}`
                : "welcome, friend"}
            </div>
          </div>
          <ul className="nav-list width-third">
            <li>
              <NavLink exact to="/" activeClassName="nav-selected">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/privacy" activeClassName="nav-selected">
                Privacy
              </NavLink>
            </li>
          </ul>
        </div>
        <hr />
      </>
    );
  }
}

export default Header;
