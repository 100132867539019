import React from "react";
import { v4 as uuid4 } from "uuid";
import { Results } from "./Results";
import { SearchForm } from "./Search";

const AUTO = {
  none: "none",
  search: "search",
}

class Home extends React.Component {
  state = {
    simioticsSearchURL: process.env.REACT_APP_SIMIOTICS_SEARCH_URL,
    clientID: uuid4(),
    previous: {
      query: "",
      results: []
    },
    query: null,
    message: "",
    auto: AUTO.none,
  };

  componentDidMount() {
    const clientID = localStorage.getItem('BUGOUT_CLIENT_ID');
    if (clientID !== this.state.clientID) {
      if (!clientID) {
        localStorage.setItem('BUGOUT_CLIENT_ID', this.state.clientID)
      } else {
        this.setState({clientID});
      }
    }

    if (this.props.location && this.props.location.search) {
      const queryParameters = new URLSearchParams(this.props.location.search);
      let update = {};
      const query = queryParameters.get('q');
      if (query) {
        update.query = decodeURIComponent(query);
      }

      const queryClientID = queryParameters.get('clientID');
      if (queryClientID) {
        update.clientID = queryClientID;
      }

      const autoRaw = queryParameters.get('auto');
      update.auto = AUTO[autoRaw] || AUTO.none;

      this.setState(update);
    }
  }

  search = async query => {
    this.props.history.push(`/?q=${query}`);
    return fetch(
      `${this.state.simioticsSearchURL}/aggregate?q=${encodeURIComponent(query)}`,
      {
        method: "GET",
        headers: {
          "x-simiotics-client-id": this.state.clientID
        }
      }
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(response => {
        this.setState({
          previous: {
            query: query,
            results: response.results
          },
          query: null,
          message: ""
        });
      })
      .catch(err =>
        this.setState({
          message: `There was an issue getting results for your search: ${err}`
        })
      );
  };

  render() {
    return (
      <div className="container">
        <SearchForm onSearch={this.search} query={this.state.query} autoSearch={this.state.auto === AUTO.search} />
        <Results query={this.state.previous.query} results={this.state.previous.results || []} />
      </div>
    );
  }
}

export default Home;
